<style lang="scss" scoped>
    .demand-sheet {

        .demand-sheet-table {
            background: #fff;
            margin-top: 10px;

            .not-through-popover {
                display: inline-block;
                cursor: pointer;

                .el-popover__reference {
                    color: #FF3B30;
                }
            }

            .el-button {
                padding: 0;
                margin: 5px;
                margin-left: 0;
            }
        }
    }

    .demand-sheet /deep/ {
        .start-play-city {
            .select-city-pub.city-pub_border {
                .el-select .el-input.is-focus .el-input__inner {
                    border: 1px solid #dcdfe6;
                }

                .el-input__inner {
                    border: 1px solid #dcdfe6;
                    padding-right: 15px;
                }

                .el-input__suffix {
                    display: block;
                    opacity: 1;
                }
            }
        }
    }

</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont demand-sheet">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form" size="small">
                            <el-form-item label="" class="el-label_w">
                                <el-radio-group v-model="form.orderStatus">
                                    <el-radio-button label="">全部</el-radio-button>
                                    <el-radio-button v-for="(item, index) in demandStateOptions"
                                                     :key="index" :label="item.value">
                                        {{item.label}}<span v-if="item.value == 10">{{waitQouteCount}}</span>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="">
                                <el-input v-model="form.orderNo" placeholder="需求单ID" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="form.productForm" placeholder="请选择类型">
                                    <el-option label="全部类型" value=""></el-option>
                                    <el-option
                                            v-for="(item,ixType) in productOptions"
                                            :key="ixType"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="form.merchantName" placeholder="下单机构"></el-input>

                            </el-form-item>

                            <el-form-item label="">
                                <el-input v-model="form.loginuserName" placeholder="下单人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="form.loginuserMobile" placeholder="下单人手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="" class="start-play-city">
                                <select-city :defaultCity="form.productStartCityName"
                                             @update:getCity="selectTripCityName">
                                </select-city>
                            </el-form-item>

                            <!--                            :default-city="$store.state.location.cityName"-->

                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="handleReset">重置</el-button>
                        <el-button type="primary" @click="getOrderCustomize()">搜索</el-button>
                    </div>
                </com-list-search>


                <div class="demand-sheet-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              :data="demandTable"
                              v-loading="demandTableLoading"
                              :height="tableH"
                              style="width: 100%">
                        <el-table-column
                                prop="orderNo"
                                label="需求单ID"
                                width="110">
                        </el-table-column>
                        <el-table-column
                                label="类型"
                                min-width="50px">
                            <template slot-scope="scope">
                                <p>
                                    {{scope.row.productForm | productTypeDesc}}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="下单人"
                                min-width="60">
                            <template slot-scope="scope">
                                <p v-if="scope.row.merchantName">{{'[' + scope.row.merchantName + ']'}}</p>
                                <p>{{'[' + scope.row.createLoginuserName + ']'}}</p>
                                <p>{{'[' + scope.row.createLoginuserMobile + ']'}}</p>
                                <!--                                <span v-text="scope.row.businessContractName"></span>-->
                                <!--                                <span v-text="scope.row.businessContractPhone"></span>-->
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="下单时间"
                                min-width="60">
                            <template slot-scope="scope">
                                {{scope.row.createTime | dateCus("yyyy-MM-dd hh:mm:ss")}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="预订信息"
                                min-width="120">
                            <template slot-scope="scope">
                                <p>用车时间：{{scope.row.reserveTime | dateCus("yyyy-MM-dd hh:mm")}}</p>
                                <p>出发地址：{{scope.row.departure}}</p>
                                <p>送达地址：{{scope.row.destination}}</p>
                                <p v-if="scope.row.vehicleType">车型需求：<span>{{formatCarModel(scope.row.vehicleType).name}}</span>
                                </p>
                                <p>出行人数：{{scope.row.playPersonNumber}}</p>
                                <p>出行人：{{scope.row.tripUserName}}{{scope.row.tripUserMobile}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="报价"
                                min-width="30">
                            <template slot-scope="scope">
                                <p v-if="scope.row.orderAmount">￥{{scope.row.orderAmount}}</p>
                                <p v-else>￥0.00</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="状态"
                                min-width="30">
                            <template slot-scope="scope">
                                <p>
                                    {{scope.row.orderStatus | demandStateDesc}}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                :class-name="('table-info-operation')"
                                label="操作"
                                width="100">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="scope.row.orderStatus != 16 && scope.row.orderStatus != 72"
                                           @click="cancelCustomizeOrder(scope.row.orderId)">
                                    取消
                                </el-button>
                                <el-button type="text" v-if="scope.row.orderStatus != 16 && scope.row.orderStatus != 72"
                                           @click="goEditBtn(scope.row.orderId)">
                                    编辑并报价
                                </el-button>
                                <!--<el-button type="text" @click="seeListInfoClick(scope.row.orderId)">
                                    日志
                                </el-button>-->
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="handleTablePageNumberChange"
                            :current-page.sync="pageData.currentPage"
                            :page-size="pageData.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageData.total">
                    </el-pagination>
                </div>
            </div>
        </el-footer>

    </div>

</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'
    import selectCity from '@/components/selectCity/'
    import {getSeatModel} from '@/data/index'
    import {findItem} from '@/common/js/common'

    const productOptions = [{
        value: '12',
        label: '实时用车'
    }, {
        value: '2',
        label: '旅游包车'
    }, {
        value: '13',
        label: '旅游线路'
    }];
    const demandStateOptions = [{
        value: '10',
        label: '待报价'
    }, {
        value: '11',
        label: '已报价'
    }, {
        value: '16',
        label: '已成单'
    }, {
        value: '72',
        label: '已取消'
    }]
    export default {
        //定义模版数据
        data() {
            return {
                tableH: 'calc(100vh - 335px)',

                merchantLoading: false,
                waitQouteCount: '',  // 待报价数量
                demandStateOptions, // 需求单状态
                productOptions,  // 产品类型
                form: {
                    orderStatus: '',  // 价格状态
                    productForm: '',  // 类型
                    // orderNo:'',  // 需求单id
                    // merchantName:'',  // 下单机构
                    // createLoginuserName:'',  // 下单人姓名
                    // loginuserMobile:'',  // 下单人姓名
                },
                merchantOp: null,
                demandTableLoading: false,
                demandTable: null,
                pageData: {  // 列表分页
                    pageSize: 10,  // 每页显示条目个数
                    total: 1,  // 总条目个数
                    currentPage: 1,  // 当前页数
                },
            }
        },
        components: {comListSearch, selectCity},
        filters: {
            demandStateDesc(value) {
                return findItem(demandStateOptions,value)
            },
            productTypeDesc(value) {
                return findItem(productOptions,value)
            }
        },
        //计算属性
        computed: {},
        created: function () {
            this.getOrderCustomize();
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handleTablePageNumberChange(val) {
                this.pageData.currentPage = val;
                this.getOrderCustomize()
                // this.getList();
            },
            handleReset() {
                this.form = {
                    orderStatus: '',  // 价格状态
                    productForm: '',  // 类型
                    productStartCityName: '',
                };
                this.getOrderCustomize()
            },

            selectTripCityName(city) {
                this.form.productStartCityName = city;
            },

            async getOrderCustomize() {  //
                this.loadingShow();
                let form = {
                    pageIndex: this.pageData.currentPage,
                    pageSize: this.pageData.pageSize
                };
                if (this.form.orderNo) form.orderNo = this.form.orderNo;  // 需求单id
                if (this.form.loginuserMobile) form.loginuserMobile = this.form.loginuserMobile;  // 下单人手机
                if (this.form.loginuserName) form.loginuserName = this.form.loginuserName;  // 下单人姓名
                if (this.form.merchantName) form.merchantName = this.form.merchantName;  // 下单机构

                if (this.form.orderStatus) form.orderStatus = this.form.orderStatus;  // 订单状态
                if (this.form.productForm) form.productForm = this.form.productForm;  // 类型
                if (this.form.productStartCityName) form.productStartCityName = this.form.productStartCityName;  // 城市
                let res = await this.http('/galaxyOrderCustomize/list', form, 'POST');
                if (res.success) {
                    this.demandTable = res.data.data;
                    this.demandTable.forEach(item => {
                        if (item.departure) item.departure = JSON.parse(item.departure).address + JSON.parse(item.departure).value;
                        console.log(item.destination);
                        if (item.destination) item.destination = JSON.parse(item.destination).address + JSON.parse(item.destination).value;
                    })
                    this.pageData.total = res.data.total;
                    this.getOrderStatusCount()
                    this.loadingHide();
                }
            },
            async cancelCustomizeOrder(orderId) {
                let form = {
                    orderId: orderId
                }

                let res = await this.http('/galaxyOrderCustomize/cancelCustomizeOrder', form, 'POST')
                if (res.success) {
                    this.$message({
                        message: '需求定制取消成功',
                        type: 'success'
                    });
                    this.getOrderCustomize()
                }
            },
            goEditBtn(id) {
                this.$router.replace('/demandSheetEdit/' + id);
            },


            async getOrderStatusCount() {
                let res = await this.http('/galaxyOrderCustomize/orderStatusCount')
                if (res.success) {
                    this.waitQouteCount = res.data.waitQouteCount
                }
            },  // 获取需求定制订单的待报价状态的数量
            formatCarModel(type) {
                let info = {};
                if (type) {
                    info = getSeatModel(parseInt(type));
                    // console.log(info, type);
                }
                return info;
            },

        },
        //监听模版变量
        watch: {}

    }
</script>

